<template>
  <q-select
    :disable="disable"
    dense
    outlined
    v-model="value.traffic_source_names"
    :options="trafficCampaigns"
    :label="$t('label.audience.inflow_route')"
    color="primary"
    options-selected-class="primary"
    style="width: 25%"
    option-value="_id"
    option-label="label"
    emit-value
    map-options
    multiple
    class="q-mb-sm"
    id="traffic"
  >
    <template v-if="value.traffic_source_names && value.traffic_source_names.length > 0" v-slot:append>
      <q-icon name="cancel" @click.stop="onClearOptionChange" class="cursor-pointer" />
    </template>
    <template v-slot:option="scope">
      <q-expansion-item
        dense
        expand-separator
        group="somegroup"
        :default-opened="hasChild(scope)"
        header-class="text-weight-bold"
        :label="scope.opt.label"
        @click="selectedCampaign(scope.opt)"
      >
        <template v-for="child in scope.opt.children" :key="child._id">
          <q-item dense clickable v-ripple :class="{ 'bg-light-blue-1': isSelected(child._id) }">
            <q-checkbox v-model="selectedCampaignIds" :val="child._id" @click="selectedCampaign(child)">
              <q-item-label class="q-ml-md">{{ child.source_name }}</q-item-label></q-checkbox
            >
          </q-item>
        </template>
      </q-expansion-item>
    </template>
  </q-select>
</template>

<script lang="ts">
import { maska } from 'maska'
import { Options, mixins } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { IAudience, ICampaign, ITrafficSource } from '@/utils/types'
import AudienceMixin from '../mixins/AudienceMixin.vue'
import { ACTION_CAMPAIGN, ACTION_TRAFFIC_SOURCE } from '@/store/actions'

@Options({
  components: {},
  directives: { maska },
  emits: ['update:modelValue'],
})
export default class TrafficSourceDropdownSelector extends mixins(AudienceMixin) {
  @Prop({})
  modelValue!: IAudience

  @Prop({})
  disable!: boolean

  selectedCampaignIds: string[] = []

  trafficCampaigns: object[] = []

  get selectedAppId() {
    return this.$route.params.app_id
  }

  get value() {
    return this.modelValue
  }

  set value(value: IAudience) {
    this.$emit('update:modelValue', value)
  }

  selectedCampaign(item) {
    if (!item._id) {
      return
    }
    if (this.value.traffic_source_ids) {
      const index = this.value.traffic_source_ids.findIndex(({ _id }) => _id === item._id)
      if (index !== -1) {
        this.value.traffic_source_ids.splice(index, 1)
      } else {
        this.value.traffic_source_ids.push(item)
      }
      const titles = this.value.traffic_source_ids.map(function (item) {
        return item.source_name
      })

      this.value.traffic_source_names = titles.join(', ')
    }
  }

  hasChild(scope) {
    if (scope.opt.children && this.value.traffic_source_ids && this.value.traffic_source_ids.length > 0) {
      const id = this.value.traffic_source_ids[0]._id
      return scope.opt.children.some((c) => c._id === id)
    }
  }

  isSelected(id) {
    if (this.value.traffic_source_ids) {
      return this.value.traffic_source_ids.some((c) => c._id === id)
    }

    return false
  }

  async loadCampaign() {
    if (!this.selectedAppId) {
      return
    }
    const campaigns: ICampaign[] = await this.$store.dispatch(ACTION_CAMPAIGN.LOAD_ITEMS, {
      app_id: this.selectedAppId,
      is_traffic_source: true,
    })

    for (const element of campaigns) {
      const sources: ITrafficSource[] = await this.$store.dispatch(ACTION_TRAFFIC_SOURCE.LOAD_ITEMS, {
        app_id: this.selectedAppId,
        campaign_id: element._id,
      })

      this.trafficCampaigns.push({
        label: element.campaign_name,
        children: sources,
      })
    }

    const sources: ITrafficSource[] = [
      {
        _id: 'user_import',
        app_id: this.selectedAppId,
        source_name: this.$t('menu.user_import'),
      },
    ]

    this.trafficCampaigns.push({
      label: this.$t('menu.manage_users'),
      children: sources,
    })
  }

  onClearOptionChange() {
    this.value.traffic_source_ids = []
    this.value.traffic_source_names = ''
    this.selectedCampaignIds = []
  }
}
</script>
